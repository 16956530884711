import React, { useState } from 'react'

import { getShoppingList, saveShoppingList } from '../util/storage'

import "./ShowShoppingItem.css"

const ShowShoppingItem = (props) => {
  const { name } = props
  
  const [editMode, setEditMode] = useState(false)
  const [editedName, setEditedName] = useState(name)
  const [checked, setChecked] = useState(false)

  const toggleShoppingListItem = (event) => {
    if (editMode) {
      return 
    }

    const remove = !checked
    const shoppingList = getShoppingList()

    if (remove) {
      // from current shopping list, need to remove `id`
      const newShoppingList = shoppingList.filter(item => item.name !== editedName)
      saveShoppingList(newShoppingList)
    } else {
      // to current shopping list, need to add `id`
      shoppingList.push({name: editedName})
      saveShoppingList(shoppingList)
    }

    setChecked(!checked)
  }

  const shoppingItemDoubleClick = () => {
    setChecked(false)
    setEditMode(true)
  }

  const handleNameChange = (event) => {
    const newName = event.target.value
    const shoppingList = getShoppingList()

    const newShoppingList = shoppingList.filter(item => item.name !== editedName)
    newShoppingList.push({name: newName})
    saveShoppingList(newShoppingList)

    setEditedName(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setEditMode(false)
    }
    if (event.keyCode === 27) {
      // Escape
      setEditMode(false)
    }
  }

  const cssClass = `shopping-item ${checked ? 'checked' : ''}`

  return (
    <div className={cssClass}>
      <div className="item-details">

        {editMode ? (
          <input
            type="text"
            value={editedName}
            onChange={handleNameChange}
            onKeyDown={handleKeyDown}
            onBlur={() => setEditMode(false)}
          />
        ) : (
          <div className="name"
            onClick={(event) => toggleShoppingListItem(event)} 
            onDoubleClick={() => shoppingItemDoubleClick()}
          >{editedName}</div>
        )}
      </div>
    </div>)
}

export default ShowShoppingItem
