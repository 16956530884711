import axios from 'axios'

import { getApiServerUrl } from '../config.js'
import { getRecentRecipes, loadAllIngridients, saveAllIngridients, saveRecentRecipes, loadHomeIngridients } from './storage.js'
import { publish } from '../events'

const getAllFoodFromApi = async () => {
    return getIngredientsFromServer()
}

const getIngredientsFromServer = async () => {
    const lastIngredientsUpdate = window.localStorage.getItem('ingredients_last_update')
    const currentTimestamp = Math.floor(new Date().getTime() / 1000)

    const localIngredients = loadAllIngridients()

    // a bit for optimization to avoid every single call for ingredients,
    // we check for new ingredients only if there was more than 5 minutes from last update
    if ((!localIngredients || !localIngredients.length) || (currentTimestamp - lastIngredientsUpdate > 1 * 60 * 5)) {
        const apiUrl = getApiServerUrl()

        const response = axios.get(`${apiUrl}/ingredient`, {
            params: {
                lastUpdated: lastIngredientsUpdate
            },
        })

        response.then(ingredientsList => {
            if (ingredientsList.status === 200) {
                saveAllIngridients(ingredientsList.data)
            }
            window.localStorage.setItem('ingredients_last_update', currentTimestamp)
        })
    }

    return new Promise((resolve) => { resolve(localIngredients) })
}

const getAllFood = async () => {
    return getAllFoodFromApi();
}

const createNewWeekMenu = async (day_number) => {
    const days = day_number
    const recipeIds = []
    const homeIngredients = loadHomeIngridients()
    for (let day = 0; day < days; day++ ) {
        const recipe = await findRandomRecipe(homeIngredients, 'main_course');
        recipeIds.push(recipe);
    }

    return recipeIds
}

const findRandomRecipe = async (ingredients = [], type = '' , timeFilter = []) => {
    const apiUrl = getApiServerUrl()
    const url = `${apiUrl}/recipe`

    const recentRecipes = getRecentRecipes()

    publish('showLoading');

    const params = {
        typeOfMeals: type,
        ingredients: ingredients.map(i => i.id).join(','),
        cookingTimes: timeFilter.join(','),
        recentRecipes: recentRecipes.join(','),
    }

    try {
        const response = await axios.get(url, { params })

        publish('hideLoading');

        if (response.data.id) {
            const newRecentRecipes = recentRecipes.filter(r => r != response.data.id)
            newRecentRecipes.push(response.data.id)

            if (newRecentRecipes.length > 10) {
                newRecentRecipes.shift()
            }

            saveRecentRecipes(newRecentRecipes)
        }

        return response.data;
    } catch (e) {
        console.log(e);
    }

    publish('hideLoading');
    return {}
}

const findRandomRecipesWithTags = async (ingredients = [], type = '' , tags = [], timeFilter = []) => {
    const apiUrl = getApiServerUrl()
    const url = `${apiUrl}/recipe/with-tags`

    const recentRecipes = getRecentRecipes()

    publish('showLoading');

    const params = {
        typeOfMeals: type,
        ingredients: ingredients.map(i => i.id).join(','),
        cookingTimes: timeFilter.join(','),
        recentRecipes: recentRecipes.join(','),
        recipeTags: tags.join(','),
    }

    try {
        const response = await axios.get(url, { params })

        publish('hideLoading');

        if (response.data.id) {
            const newRecentRecipes = recentRecipes.filter(r => r != response.data.id)
            newRecentRecipes.push(response.data.id)

            if (newRecentRecipes.length > 10) {
                newRecentRecipes.shift()
            }

            saveRecentRecipes(newRecentRecipes)
        }

        return response.data;
    } catch (e) {
        console.log(e);
    }

    publish('hideLoading');
    return []
}

const findRecipeAlternative = async (recipeId) => {
    const apiUrl = getApiServerUrl()
    const url = `${apiUrl}/recipe/alternatives/${recipeId}`

    publish('showLoading');

    try {
        const response = await axios.get(url)

        publish('hideLoading');

        return response.data;
    } catch (e) {
        console.log(e);
    }

    publish('hideLoading');
    return {}
}

const findRecipeAlternatives = async (ingredients = [], type = '') => {
    const apiUrl = getApiServerUrl()
    const url = `${apiUrl}/recipe/alternatives`

    publish('showLoading');

    const params = {
        typeOfMeals: type,
        ingredients: [] // ingredients,
    }

    try {
        const response = await axios.get(url, { params })

        publish('hideLoading');

        return response.data;
    } catch (e) {
        console.log(e);
    }

    publish('hideLoading');
    return {}
}

const findRecipe = async (id) => {
    const apiUrl = getApiServerUrl()
    const url = `${apiUrl}/recipe/${id}`

    try {
        const response = await axios.get(url, {})
        return response.data;
    } catch (e) {
        console.log(e);
    }
    return {}
}

export { findRandomRecipe, findRandomRecipesWithTags, findRecipeAlternative, findRecipeAlternatives, findRecipe, getAllFood, createNewWeekMenu }
