import { findRandomRecipe } from "../util/api"
import { loadHomeIngridients, loadFoodCategories, saveSuggestedRecipes, loadCookingTimeFilter } from './storage'

const findTodayRecipe = async () => {
    const homeIngredients = loadHomeIngridients()
    const foodCategories = loadFoodCategories()
    const timeFilter = loadCookingTimeFilter()

    const idForCategories = await Promise.all( 
        foodCategories.map( async category => {
            const recipies = await findRandomRecipe(homeIngredients, category, timeFilter)
            
            if (recipies) {
                return recipies.id
            }
            return null
        })
    )

    saveSuggestedRecipes(idForCategories.filter(id => id))

    const ids = idForCategories.filter(id => id).join(',')
    return `/recipe-view/${ids}`
}

function randomToMax(max) {
    return Math.floor(Math.random() * (max + 1))
}


export { findTodayRecipe }
