import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import { versionChangeDataClear } from './util/storage'

import './index.css';

import mixpanel from 'mixpanel-browser';
mixpanel.init('3d4177cf846827accf3b5002d260c983', { debug: false, track_pageview: true, persistence: 'localStorage' });

versionChangeDataClear();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
