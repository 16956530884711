import { useNavigate } from "react-router-dom"
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser';

import HomeFoodItem from '../components/HomeFoodItem'
import SelectableFoodList from '../components/SelectableFoodList'
import SelectHomeFood from '../components/HomeFoodInput'

import { getHomeIngredients, saveHomeIngredients } from '../util/ingredients'
import { findTodayRecipe } from "../util/recipe"

import './FoodAtHome.css'

const FoodAtHome = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const initialHomeFood = getHomeIngredients()

    const [homeFood, setHomeFood] = useState(initialHomeFood);

    const [foodInputFilter, setFoodInputFilter] = useState('')

    const today = async () => {
        mixpanel.track('FoodAtHome-today recipe')
        const url = await findTodayRecipe()
        navigate(url)
    }

    const updateHomeFoodWithSelection = (food) => {
        setFoodInputFilter('')
        const foodList = [].concat(homeFood, [food])
        setHomeFood(foodList)
        saveHomeIngredients(foodList)
    }

    const removeFromHomeFood = (food) => {
        const foodList = homeFood.filter(f => f.id !== food.id)
        setHomeFood(foodList)
        saveHomeIngredients(foodList)
    }

    return <div className="food-at-home">
        <div className='page-title'>{t('app.screen.foot-at-home')}</div>
        <div className='have-food'>
            {homeFood.map((ingredient, index) => 
                <HomeFoodItem ingredient={ingredient} key={`${ingredient.id}-${index}`} onClick={removeFromHomeFood} />
            )}
        </div>

        <div className="button-language" onClick={()=>today()}>
            <a>{t('What are we eating today?')}</a>
        </div>

        <div className="enter-food">
            <SelectHomeFood onFilter={setFoodInputFilter} inputValue={foodInputFilter} />
            <div >
                <SelectableFoodList 
                    foodInputFilter={foodInputFilter} 
                    selectedFood={homeFood} 
                    onFoodSelect={updateHomeFoodWithSelection} 
                />
            </div>
        </div>
    </div>
}

export default FoodAtHome;