import { useParams, useNavigate } from "react-router-dom"

import RecipeSummaryView from '../components/RecipeSummaryView'
import { loadHomeIngridients, loadFoodCategories } from '../util/storage'
import { findRecipeAlternative, findRecipeAlternatives } from '../util/api'

import { useTranslation } from "react-i18next"

import './RecipeAlternativesView.css'

import BackButton from '../images/back.png'
import { useEffect, useState } from "react"

export default function RecipesAlternativesView({pageTitle = 'app.scree.recipe-alternatives'}) {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [recipes, setRecipes] = useState([])

    const homeIngridients = loadHomeIngridients()
    const foodCategories = loadFoodCategories()
    const selectedFoorCategories = foodCategories // Object.keys(foodCategories).filter(fc => foodCategories[fc])

    useEffect( () => {
        if (id) {
            findRecipeAlternative(id).then( recipesList => {
                setRecipes(recipes.concat(recipesList))
            })
        } else {
            selectedFoorCategories.forEach(category => {
                findRecipeAlternatives(homeIngridients, category).then( recipesList => {
                    if (recipesList && recipesList.length) {
                        setRecipes(recipes.concat(recipesList))
                    }
                })
            })
        }
    }, [])

    return (
        <>
            <div className="recipe-alternatives-view">
                <div className="recipe-alternatives-view-title">
                    <div className="back-navigation" onClick={() => navigate(-1)}>
                        <img src={BackButton} style={{width: '20px'}}/>
                    </div>
                    <div className="recipe-title">{t(pageTitle)}</div>
                </div>
                { recipes.map( recipe => <RecipeSummaryView recipe={recipe} mixpanelEvent="RecipeAlternatives" key={recipe.id} /> )}
            </div>
        </>
    )
}
