
const LOCAL_API_URL = 'http://localhost:8080';
const SERVER_API_URL = 'https://api.foodtips.me';

export const APP_VERSION = "1.1"

export function getApiServerUrl() {
    if (process.env.NODE_ENV === 'production') {
        return SERVER_API_URL
    } else {
        return SERVER_API_URL // LOCAL_API_URL
    }
}

