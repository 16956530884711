import { translatedTitle } from '../util/i18n'
import { getFoodTranslation } from '../util/ingredients'

const FoodName = ({food}) => {
    if (food.title) {
        return translatedTitle(food.title)
    }
    if (food.id) {
        return food.id
    }

    const translations = getFoodTranslation(food.id)
    if (translations) {
        const translated = translatedTitle(translations)
        return translated
    }

    return food
}

export default FoodName