import { useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { setUserLanguage } from "../util/usersettings"

export default function Language(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const { i18n } = useTranslation();

    useEffect(() => {
      if (location.search.includes('select')) {
        return 
      }

      const storedLang = localStorage.getItem('language')
      if (storedLang) {
          navigate('/main')
          return
      }
    })

    const setLanguage = (lang) => {
        setUserLanguage(lang)
        i18n.changeLanguage(lang);
        navigate('/main')
    }

    return (
        <div style={{width: '80%', marginTop: '5em'}}>
          <div className="button-language" onClick={()=>setLanguage('lt')}>
            <a onClick={()=>setLanguage('lt')}>Lietuviškai</a>
          </div>
          <div className="button-language" onClick={()=>setLanguage('no')}>
            <a onClick={()=>setLanguage('no')}>Norsk</a>
          </div>
          <div className="button-language" onClick={()=>setLanguage('en')}>
            <a onClick={()=>setLanguage('en')}>English</a>
          </div>
        </div>
    )
}