import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Chips, Switch } from '@navikt/ds-react'

import HomeFoodItem from '../components/HomeFoodItem'
import RecipeTags from '../components/RecipeTags'
import SelectableFoodList from '../components/SelectableFoodList'
import SelectHomeFood from '../components/HomeFoodInput'
import { findRandomRecipesWithTags } from '../util/api'
import { loadHomeIngridients, saveHomeIngridients, loadFoodCategories, saveFoodCategories, DEFAULT_FOOD_CATEGORIES } from '../util/storage'

import './SelectionFilter.css'

export default function SelectionFilter () {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [foodCategories, setFoodCategories] = useState(loadFoodCategories())
    const [homeIngridients, setHomeIngridients] = useState(loadHomeIngridients())
    const [filterWithAll, setFilterWithAll] = useState(true)
    const [tags, setTags] = useState([])
    const [foodInputFilter, setFoodInputFilter] = useState('')
    const [homeFood, setHomeFood] = useState(loadHomeIngridients())

    const [step, setStep] = useState(1)

    const setFoodCategoriesWithSave = (categories) => {
        saveFoodCategories(categories)
        setFoodCategories(categories)
    }

    const findRecipes = () => {
        const type = foodCategories[0]
        const response = findRandomRecipesWithTags(filterWithAll ? [] : homeIngridients, type, tags)
        response.then((recipes) => {
            if (recipes && recipes.length) {
                const recipeIds = recipes.map((recipe) => recipe.id).join(',')
                navigate(`/recipes-view/${recipeIds}`)
            } else {
                alert(t("Can't find any recipes, try different tags or type."))
            }
        })
    }

    const updateFoodCategories = (category) => {
        setFoodCategoriesWithSave([category])
    }

    const updateHomeFoodWithSelection = (food) => {
        console.log('food', food)
        setFoodInputFilter('')
        const foodList = [].concat(homeFood, [food])
        setHomeFood(foodList)
        saveHomeIngridients(foodList)
    }

    const removeFromHomeFood = (food) => {
        const foodList = homeFood.filter(f => f.id !== food.id)
        setHomeFood(foodList)
        saveHomeIngridients(foodList)
    }

    return (
        <div className="recipe-filter" style={{ width: '100%' }}>
            <div className='page-title'>{t('app.screen.filter-food-selection')}</div>

            { step === 1 && 
                <>
                <div className="full-width food-filter-categories">
                    <Chips>
                        {DEFAULT_FOOD_CATEGORIES.map((foodCategory) =>
                            <Chips.Toggle key={foodCategory} selected={foodCategories?.includes(foodCategory)} onClick={() => updateFoodCategories(foodCategory)}>
                                {t(foodCategory)}
                            </Chips.Toggle>
                        )}
                    </Chips>
                </div>
                <div className="find-recipes">
                    <button className="button-language" onClick={() => setStep(step + 1)}>{t('Next')}</button>
                </div>
                </>
            }

            { step === 2 &&
            <div>
                <RecipeTags tags={tags} setTags={setTags} />
            </div>
            }

            { false && 
            <div className="enter-food">
                <label>{t('app.screen.filter-food-selection.ingredients-to-use')}:</label>
                <div className="food-at-home">
                {homeFood.map((ingredient, index) => 
                    <HomeFoodItem ingredient={ingredient} key={`${ingredient.id}-${index}`} onClick={removeFromHomeFood} />
                )}
                </div>
                <SelectHomeFood onFilter={setFoodInputFilter} inputValue={foodInputFilter} />
                <div >
                    <SelectableFoodList 
                        foodInputFilter={foodInputFilter} 
                        selectedFood={homeFood} 
                        onFoodSelect={updateHomeFoodWithSelection} 
                        useFoodInputFilter={false}
                    />
                </div>
            </div>
            }

            { step === 2 &&
            <div className="find-recipes">
                <button className="button-language" onClick={() => findRecipes()}>{t('Select')}</button>
            </div>
            }
        </div>
    )
}