import { useEffect, useState } from "react";
import { getAvailableVoiceLanguages, findVoiceLanguage } from "../util/speech";

const Debug = () => {
    const [speechLanguages, setSpeechLanguages] = useState(null)
    const [voiceLangLt, setVoiceLangLt] = useState(null)
    const [voiceLangNo, setVoiceLangNo] = useState(null)
    const [voiceLangEn, setVoiceLangEn] = useState(null)

    useEffect(() => {
        getAvailableVoiceLanguages().then( langs =>  {
            setSpeechLanguages(langs)
        })

        findVoiceLanguage('lt').then( lt => setVoiceLangLt(lt) )
        findVoiceLanguage('no').then( no => setVoiceLangNo(no) )
        findVoiceLanguage('en').then( en => setVoiceLangEn(en) )
    }, [])

    let speechLang = '';

    if (null === speechLanguages || undefined === speechLanguages) {
        speechLang = 'speech is not available'
    } else if (!speechLanguages.length) {
        speechLang = 'no voices'
    } else {
        speechLang = speechLanguages.join(', ')
    }

    return <div>
        
        <div>Speech languages: {speechLang}</div>
        <div>
            <div>Voice lang for lt: {voiceLangLt}</div>
            <div>Voice lang for no: {voiceLangNo}</div>
            <div>Voice lang for en: {voiceLangEn}</div>
        </div>
    </div>
}

export default Debug;