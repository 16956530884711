import i18n from 'i18next';
import { initReactI18next } from 'react-i18next'
import en from './i18n/en'
import no from './i18n/no'
import lt from './i18n/lt'
import { userLanguage, setUserLanguage } from './usersettings'

const languageDetector = {
  type: 'languageDetector',
  async: true,
  init: () => {},
  detect: async function (callback) {
    try {
      const language = userLanguage();
      if (language) {
        return callback(language);
      }
      return callback('');
    } catch (error) {
    }
  },
  cacheUserLanguage: async function (language) {
    try {
        console.log('cache user language', language)
        //setUserLanguage(language);
    } catch (error) {}
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {en, no, lt},
  });

const translatedTitle = (title) => {
  const lang = userLanguage();
  return title[lang] || title['en'] || title['lt'] || title['no'] || title;
}

const availableLanguages = () => {
  return ['en', 'no', 'lt']
}

export { translatedTitle, availableLanguages };

export default i18n;
