import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ShowShoppingItem from '../components/ShowShoppingItem'
import AddShoppingListItem from '../components/AddShoppingListItem'
import { getShoppingList } from '../util/storage'

import "./ShoppingList.css"

function ShoppingList () {
  const [foodList, setFoodList] = useState(getShoppingList(), [])

  const { t } = useTranslation()

  return (
    <div className="shopping-list">
      <div className='page-title'>{t('app.screen.shopping-list')}</div>
      <AddShoppingListItem setFoodList={setFoodList}/>
      {foodList.length === 0 ? (
        <div className="no-item">
          {t('empty shopping list')}
        </div>
      ) : (
        <div className="shopping-list-items">
          {foodList.map((item) => 
            <ShowShoppingItem key={item.name} name={item.name} />
          )}
        </div>)
      }
    </div >
  )
}

export default ShoppingList
