import { useState } from 'react'
import mixpanel from 'mixpanel-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
import "./ShareApp.css"
import { useTranslation } from 'react-i18next'

function ShareApp (props) {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()
  const url = props.url
  const title = props.title || t('app.screen.profile.share-app')
  const linkTitle = props.linkTitle || t('app.screen.profile.share-app')

  function shareLink (appType) {

    switch (appType) {
      case 'instgram':
        window.open(`https://www.instagram.com/?url=${encodeURIComponent(url)}`)
        break
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`)
        break
      case 'twitter':
        window.open(`https://twitter.com/share?url=${encodeURIComponent(url)}`)
        break
      case 'linkedin':
        window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}`)
        break
      case 'email':
        window.location.href = `mailto:?body=${encodeURIComponent(url)}`
        break
      default:
        break
    }
  }

  function handleCloseModal () {
    setModalOpen(false)
  }

  function handleShowModal () {
    mixpanel.track('ShareApp-show')
    setModalOpen(true)
  }

  return (
    <>
      <button onClick={handleShowModal} className='sharingbut'>
        <div className='sharingicon'>
          <FontAwesomeIcon icon={faShareAlt} /> 
          <span>{linkTitle}</span>
        </div>
      </button>

      <Modal show={modalOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('Copy and share the link below:')}</p>
          <InputGroup className="mb-3">
            <FormControl type="text" value={url} readOnly />

            <Button variant="outline-secondary" onClick={() => navigator.clipboard.writeText(link)}>
              <FontAwesomeIcon icon={faCopy} />
            </Button>

          </InputGroup>
          <div className="sharing-apps">

            <a href="#" className="sharing-app" onClick={() => shareLink('facebook')}>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="#" className="sharing-app" onClick={() => shareLink('instgram')}>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="#" className="sharing-app" onClick={() => shareLink('twitter')}>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" className="sharing-app" onClick={() => shareLink('linkedin')}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="#" className="sharing-app" onClick={() => shareLink('email')}>
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ShareApp