const allFood = []
const allFoodTranslations = []

function getFoodCategories() {
    return []
}

function getUserFood() {
    return [].concat(getHomeIngredients())
}

function getAllFood() {
    const all = [].concat(getUserFood()).filter(food => food.id)
    all.map( food => {
        allFood.push(food.id)
        if (food.title) {
            allFoodTranslations[food.id] = food.title
        }
        return food;
    })
    const unique = new Set(all.map(food => food.id))
    return [...unique].map(food => {
        return { id: food }
    }).filter(food => food)
}

function getHomeIngredients() {
    const homeIngredients = localStorage.getItem('homeIngredients')

    if (!homeIngredients) {
        return []
    }
    return JSON.parse(homeIngredients)
}

function saveHomeIngredients(ingredients) {
    localStorage.setItem('homeIngredients', JSON.stringify(ingredients))
}

function getFoodTranslation(foodId) {
    if (allFoodTranslations[foodId]) {
        return allFoodTranslations[foodId]
    }
    return foodId
}

export {
    getFoodCategories,
    getAllFood,
    getHomeIngredients,
    saveHomeIngredients,
    getFoodTranslation,
}
