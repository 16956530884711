import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { setUserLanguage } from "../util/usersettings"
import "./LanguageSelector.css"

function LanguageSelector () {
  const [showModal, setShowModal] = useState(false)
  const [language, setLanguage] = useState('en')
  const { t } = useTranslation()

  const handleSaveChanges = () => {
  }

  const location = useLocation()

  const { i18n } = useTranslation()

  useEffect(() => {
    if (location.search.includes('select')) {
      return
    }

    const storedLang = localStorage.getItem('language')
    if (storedLang) {
      setLanguage(storedLang)
      i18n.changeLanguage(storedLang)
    }
  }, [])

  const changeLanguage = (lang) => {
    setUserLanguage(lang)
    i18n.changeLanguage(lang)
  }

  return (
    <div className="language">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="languageOptions"
          id="lietuviskaiOption"
          value="Lietuviskai"
          checked={language === 'lt'}
          onChange={(e) => setLanguage('lt')}
          onClick={() => changeLanguage('lt')}
        />
        <label className="form-check-label" htmlFor="lietuviskaiOption">
          Lietuviškai
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="languageOptions"
          id="englishOption"
          value="English"
          checked={language === 'en'}
          onChange={(e) => setLanguage('en')}
          onClick={() => changeLanguage('en')}
        />
        <label className="form-check-label" htmlFor="englishOption">
          English
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="languageOptions"
          id="norskOption"
          value="Norsk"
          checked={language === 'no'}
          onChange={(e) => setLanguage('no')}
          onClick={() => changeLanguage('no')}
        />
        <label className="form-check-label" htmlFor="norskOption">
          Norsk
        </label>
      </div>
    </div>
  )
}

export default LanguageSelector