
import { isUserFavoriteRecipe } from '../util/storage'
import { FaHeart, FaHeartBroken } from 'react-icons/fa'

function FavoriteRecipeButton({recipe, onClickAdd, onClickRemove}) {
    if (isUserFavoriteRecipe(recipe)) {
        return <FaHeart className='favorite-icon' color='green' size={21} onClick={onClickRemove}/>
    } else {
        return <FaHeartBroken className='favorite-icon' color='green' size={21} onClick={onClickAdd}/>
    }
}

export default FavoriteRecipeButton
