import React, { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import mixpanel from 'mixpanel-browser';

import { Chips } from '@navikt/ds-react'
import { Button } from 'react-bootstrap'

import { DEFAULT_FOOD_CATEGORIES, DEFAULT_TIME_FILTER_NAME, saveFoodCategories, loadFoodCategories, loadDoNotAskBox, loadSuggestedRecipes, saveDoNotAskBox, loadCookingTimeFilter, saveCookingTimeFilter } from '../util/storage'

import LanguageSelector from '../components/LanguageSelector'
import ShareApp from '../components/ShareApp'


import "./Profile.css"

const getFeedbackUrl = (lang) => {
    if (lang === 'lt') {
        return 'https://foodtips.me/lt/atsiliepimas/'
    }
    if (lang === 'no') {
        return 'https://foodtips.me/nb/tilbakemelding'
    }
    return 'https://foodtips.me/feedback/'
}

const getShareRecipeUrl = (lang) => {
    if (lang === 'lt') {
        return 'https://foodtips.me/lt/pasidalink-savo-receptu/'
    }
    if (lang === 'no') {
        return 'https://foodtips.me/nb/del-din-oppskrift/'
    }
    return 'https://foodtips.me/share-your-recipes/'
}

const ShowSuggestedRecipes = () => {


    const navigate = useNavigate();
    const suggestedRecipeIds = loadSuggestedRecipes()

    if (!suggestedRecipeIds || !suggestedRecipeIds.length) {
        return null
    }

    const { t } = useTranslation()

    return <div className="suggested-recipes">
        <Button className="foodtips-btn" variant="primary" onClick={() => {
            mixpanel.track('Profile-suggested recipes')
            navigate(`/recipe-view/${suggestedRecipeIds.join(',')}`)
        }}>{t('app.screen.profile_suggested_recipes')}</Button>
    </div>
}

const Profile = () => {
    const [checked, setChecked] = useState(loadDoNotAskBox())
    const [foodCategories, setFoodCategories] = useState(loadFoodCategories())
    const [recipesCookingTime, setRecipesCookingTime] = useState(loadCookingTimeFilter())

    const { t } = useTranslation()
    const lang = localStorage.getItem('language')
    const updateFoodCategories = (category) => {
        if (foodCategories.includes(category)) {
            // need to remove
            setFoodCategoriesWithSave(foodCategories.filter((x) => x !== category))
        } else {
            // need to add
            setFoodCategoriesWithSave([...foodCategories, category])
        }
    }

    const updateRecipesCookingTime = (time) => {
        if (recipesCookingTime.includes(time)) {
            // need to remove
            const newTimeFilter = recipesCookingTime.filter((x) => x !== time)
            if (!newTimeFilter.length) {
                setTimeFilterWithSave(DEFAULT_TIME_FILTER_NAME)
            } else {
                setTimeFilterWithSave(newTimeFilter)
            }
        } else {
            // need to add
            setTimeFilterWithSave([...recipesCookingTime, time])
        }
    }

    const setFoodCategoriesWithSave = (categories) => {
        saveFoodCategories(categories)
        setFoodCategories(categories)
    }
    const setTimeFilterWithSave = (categories) => {
        saveCookingTimeFilter(categories)
        setRecipesCookingTime(categories)
    }

    function handleCheckboxChange (event) {
        setChecked(event.target.checked)
        saveDoNotAskBox(event.target.checked)
    }
    const styles = {
        toggleButton: {
            backgroundColor: '#1f856f',
        }
    }

    const feedbackUrl = getFeedbackUrl(lang)
    const shareRecipeUrl = getShareRecipeUrl(lang)

    return (
        <div className='profile'>
            <h3>{t('app.screen.profile')}</h3>
            <ShowSuggestedRecipes />
            <LanguageSelector />
            <div className='preference'>
                <div className='foodpre'>
                    <Chips >
                        {DEFAULT_FOOD_CATEGORIES.map((foodCategory) =>
                            <Chips.Toggle key={foodCategory} selected={foodCategories?.includes(foodCategory)} onClick={() => updateFoodCategories(foodCategory)}
                                style={styles.toggleButton}>

                                {t(foodCategory)}
                            </Chips.Toggle>
                        )}
                    </Chips>
                </div>
            </div>

            <div className='preference'>
                <div className='foodpre'>
                    <Chips >
                        <Chips.Toggle key="quick" selected={recipesCookingTime.includes('quick')} onClick={() => updateRecipesCookingTime('quick')} style={styles.toggleButton}>
                            &lt;= 20min
                        </Chips.Toggle>
                        <Chips.Toggle key="medium" selected={recipesCookingTime.includes('medium')} onClick={() => updateRecipesCookingTime('medium')} style={styles.toggleButton}>
                            20min - 40min
                        </Chips.Toggle>
                        <Chips.Toggle key="long" selected={recipesCookingTime.includes('long')} onClick={() => updateRecipesCookingTime('long')} style={styles.toggleButton}>
                            &gt; 40min
                        </Chips.Toggle>
                    </Chips>
                </div>
            </div>

            <div className="profile-section">
                <div className="button-secondary">
                    <a href={feedbackUrl} target="_blank" onClick={() => mixpanel.track('Profile-feedback')}>{t('app.screen.profile.give-us-feedback')}</a>
                </div>
            </div>

            <div className="profile-section">
                <div className="button-secondary">
                    <a href={shareRecipeUrl} target="_blank" onClick={() => mixpanel.track('Profile-share your recipe')}>{t('app.screen.profile.share-your-recipe')}</a>
                </div>
            </div>

            { false &&
            <div className='donot'>
                <div className='donotcore'>
                    <input
                        type="checkbox" name="doNotAsk" id='doNotAsk'
                        checked={checked}
                        onChange={handleCheckboxChange}
                    />
                    <span>{t('Do not ask if home food is empty')}</span>
                </div>
            </div>
            }
            <div className="profile-section">
                <div className="button-secondary">
                    <ShareApp url="https://app.foodtips.me/" />
                </div>
            </div>
        </div>

    )
}

export default Profile
