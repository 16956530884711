export default {
    translation: {
        'app.scree.recipe-alternatives': 'Oppskriftsalternativer',
        'app.screen.shopping-list': 'Handleliste',
        'app.screen.foot-at-home': 'Mat hjemme',
        'app.screen.profile': 'Profil',
        'app.screen.profile.food-preferences': 'Matpreferanser',
        'app.screen.profile.language': 'Språk',
        'app.screen.profile.share-app': 'Dele-app',
        'app.screen.recipeview.share-recipe': 'Dele oppskrift',
        'app.screen.recipeview.ingredients-into-shopping-list': 'Legg til i handlelisten',
        'app.screen.recipeview.all-ingredients-in-shopping-list': 'Alle produkter i handleliste',
        'app.screen.recipeview.missing-ingredients-in-home-list': "Vi kunne ikke finne oppskrift som inneholder ingredienser fra hjemmelisten din, men vi foreslår at du prøver denne oppskriften",
        'app.screen.profile_suggested_recipes': 'Siste foreslåtte oppskrift',
        'app.screen.profile.give-us-feedback': 'Gi oss tilbakemelding',
        'app.screen.profile.share-your-recipe': 'Del oppskriften din',
        'app.screen.filter-food-selection': 'Hva vil du',

        'app.screen.filter-food-selection.ingredients-to-use': 'Ingredienser du vil bruke',

        'app.screen.favoritelist.pageTitle': 'Dine favorittoppskrifter',
        'app.screen.favoritelist.empty-list': 'Du har ikke valgt noen favorittoppskrifter ennå.',

        'recipe-tag.general': 'General',
        'recipe-tag.dietary': 'Diett',
        'recipe-tag.occasion': 'Tilfelle',
        'recipe-tag.flavor': 'Smak',

        'appmenu.home': 'Hjem',
        'appmenu.food-at-home': 'Mat hjemme',
        'appmenu.shopping-list': 'Handleliste',
        'appmenu.profile': 'Profil',

        'What are we eating today?': 'Hva spiser vi i dag?',
        'Make menu for a week': 'Lag meny for en uke',
        'Recipes for inspiration': 'Oppskrifter for inspirasjon',

        //irene
        'Copy and share the link below:': 'Kopier og del lenken nedenfor:',
        'empty shopping list': 'Handlelisten din er tom.',
        'Do not ask if home food is empty': 'Ikke spør om hjemmets mat er tom',
        'Select': 'Velg',
        'Close': 'Lukk',
        'Enter an item': 'Skriv inn en gjenstand',
        'items': 'gjenstander',
        'Check all': 'Merk alle',
        'Uncheck all': 'fjern merking på alle',
        'Delete checked': 'slett merkede',
        'ingredients you have at home': 'ingredienser du har hjemme',
        'Edit': 'Rediger',
        'Save Changes': 'Lagre Endringer',
        'Cancel': 'Avbryt',
        'Select Language': 'Velg Språk"',
        'breakfast': 'frokost',
        'lunch': 'lunsj',
        'dinner': 'middag',
        'main_course': 'hovedrett',
        'soup': 'suppe',
        'dessert': 'dessert',

        'Have at home': 'Har hjemme',

        'Back': 'Tilbake',
        'brunch': 'Brunsj',
        'serving quantity': 'Serveringsmengde',
        'serving time': 'Serveringstid',
        'ingredients': 'Ingredienser',
        'cooking steps': 'Slik gjør du',
        'Alternatives': 'Alternativer',

        'all ingredients': 'Alle ingrediensene',

        'vegan': 'Vegan',
        'vegetarian': 'Vegetarian',
        'gluten-free': 'Glutenfri',
        'keto': 'Keto',
        'dairy-free': 'Meieri-fri',
        'nut-free': 'Nut-free',

        'birthday': 'Bursdag',
        'christmas': 'Christmas',
        'valentines-day': 'Valentines-day',

        'spicy': 'Spicy',
        'sweet': 'søt',
        'easter': 'Påske',

        'easy': 'lett',
        'medium': 'Medium',
        'hard': 'Hard',

        'level': 'Nivå',

        'Update weekly menu': 'Oppdater ukemeny',

        'Your favorite recipes': 'Dine favorittoppskrifter',
        'add as favorite': 'legg til som favoritt',
        'Remove favorite': 'Fjern favoritt',

    }
}
