import SelectableFoodItem from './SelectableFoodItem'

import { getAllFood } from '../util/api'

import './SelectableFoodList.css'
import { useEffect, useState } from 'react'

const isIngredientMatchingFilter = (ingredient, inputFilter) => {
    if (ingredient.id && ingredient.id.toLowerCase().includes(inputFilter)) {
        return true
    }
    if (ingredient.title) {
        const title = ingredient.title

        if (title.en && title.en.toLowerCase().includes(inputFilter)) {
            return true
        }
        if (title.lt && title.lt.toLowerCase().includes(inputFilter)) {
            return true
        }
        if (title.no && title.no.toLowerCase().includes(inputFilter)) {
            return true
        }
    }

    return false
}

export default function SelectableFoodList({foodInputFilter, selectedFood, onFoodSelect, useFoodInputFilter = true}) {
    const [allFood, setAllFood] = useState([])
    const [food, setFood] = useState([])

    const activeFilter = foodInputFilter && useFoodInputFilter ? [{id: foodInputFilter}] : []

    useEffect( () => {
        getAllFood().then( food => {
            if (!food || !food.length) {
                setTimeout(() => {
                    getAllFood().then( food => setAllFood(food) )
                }, 1500)
            } else {
                setAllFood(food) 
            }
        })
    }, [])

    useEffect(() => {
        const inputFilter = foodInputFilter ? foodInputFilter.toLowerCase() : ''
        const filteredFood = allFood
                            .filter(food => isIngredientMatchingFilter(food, inputFilter) )
                            //.filter(food => food.id && food.id.toLowerCase() !== foodInputFilter)
                            .filter(food => !selectedFood.find(e => food.id.toLowerCase() === e.id.toLowerCase()))
        setFood(filteredFood)                    
    }, [allFood, foodInputFilter])
    

    return (
        <div className="selectable-food-list">
            {[].concat(activeFilter, food).map((food, index) => 
                <SelectableFoodItem 
                    food={food} 
                    key={`${food.id}-${index}`} 
                    onSelect={onFoodSelect}
                />)
            }
        </div>
    )
}