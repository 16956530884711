
const userLanguage = () => {
    return localStorage.getItem('language')
}

const setUserLanguage = (lang) => {
    localStorage.setItem('language', lang)
}

export {
    userLanguage,
    setUserLanguage
}
