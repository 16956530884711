import { useState } from 'react'
import { Link } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import FavoriteRecipeButton from './FavoriteRecipeButton'
import { removeUserFavoriteRecipe, saveUserFavoriteRecipe } from '../util/storage'

import { translatedTitle } from '../util/i18n'

import './RecipeSummaryView.css'

const RecipeSummaryView = ({recipe, mixpanelEvent}) => {
    const [favoriteRecipeUpdate, setFavoriteRecipeUpdate] = useState(0);

    const img = recipe.images && recipe.images.length ?
        <img className="recipe-summary-view-image-img" src={recipe.images[0]}/> : null
    const authorImg = recipe.author && recipe.author.logo ?
        <img className="recipe-summary-view-author-img" src={recipe.author.logo}/> : null
    const id = recipe.id

    const addFavorite = () => {
        saveUserFavoriteRecipe(recipe)
        setFavoriteRecipeUpdate(favoriteRecipeUpdate + 1)
    }
    const removeFavorite = () => {
        removeUserFavoriteRecipe(recipe)
        setFavoriteRecipeUpdate(favoriteRecipeUpdate + 1)
    }

    return (
        <div className="recipe-summary-view" key={id}>
            <div className="recipe-summary-view-image" key="recipe-summary-view-image">
                <Link to={`/recipe-view/${id}`}
                      onClick={() => mixpanel.track(`${mixpanelEvent}-recipe image`)}>{img}</Link>
                <div className="recipe-summary-view-author">{authorImg}</div>
            </div>
            <div className="recipe-summary-view-description" key="recipe-summary-view-description">
                <div className="recipe-summary-view-title">
                    <Link to={`/recipe-view/${id}`} onClick={() => mixpanel.track(`${mixpanelEvent}-recipe title`)}>{translatedTitle(recipe.title)}</Link>
                    <div className="recipe-summary-favorite">
                        <FavoriteRecipeButton onClickAdd={addFavorite} onClickRemove={removeFavorite} recipe={recipe}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecipeSummaryView
