import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Autosuggest from 'react-autosuggest'

import FoodFilter from '../components/FoodFilter'
import { findRandomRecipe } from '../util/api'
import { loadHomeIngridients, saveHomeIngridients, loadFoodCategories, saveFoodCategories, DEFAULT_FOOD_CATEGORIES } from '../util/storage'

import { Chips, Switch } from '@navikt/ds-react'

import './RecipeFilter.css'


export default function RecipeFilter () {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [foodCategories, setFoodCategories] = useState(loadFoodCategories())
    const [homeIngridients, setHomeIngridients] = useState(loadHomeIngridients())
    const [filterWithAll, setFilterWithAll] = useState(false)

    const setHomeIngridientsWithSaving = (ingridients) => {
        saveHomeIngridients(ingridients)
        setHomeIngridients(ingridients)
    }

    const setFoodCategoriesWithSave = (categories) => {
        saveFoodCategories(categories)
        setFoodCategories(categories)
    }

    const findRecipe = () => {
        const types = foodCategories

        const recipe = findRandomRecipe(filterWithAll ? [] : homeIngridients, types)
        navigate(`/recipe-view/${recipe.id}`)
    }

    const updateFoodCategories = (category) => {
        if (foodCategories.includes(category)) {
            // need to remove
            setFoodCategoriesWithSave(foodCategories.filter((x) => x !== category))
        } else {
            // need to add
            setFoodCategoriesWithSave([...foodCategories, category])
        }
    }

    return (
        <div className="recipe-filter" style={{ width: '100%' }}>
            <div>
                <div>
                    {t('Have at home')}:
                </div>
                <FoodFilter
                    homeIngridients={homeIngridients}
                    onUpdateSelectedIngridients={selectedIngridients => setHomeIngridientsWithSaving(selectedIngridients)}
                />
                <div><Switch onChange={() => setFilterWithAll(!filterWithAll)}>{t('all ingredients')}</Switch></div>
            </div>
            <div className="full-width food-filter-categories">
                <Chips>
                    {DEFAULT_FOOD_CATEGORIES.map((foodCategory) =>
                        <Chips.Toggle key={foodCategory} selected={foodCategories?.includes(foodCategory)} onClick={() => updateFoodCategories(foodCategory)}>
                            {t(foodCategory)}
                        </Chips.Toggle>
                    )}
                </Chips>
            </div>

            <div className="recipe-alternatives">
                <button className="button-language" onClick={() => findRecipe()}>{t('Select')}</button>
            </div>
        </div>
    )
}