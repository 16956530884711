export async function getAvailableVoiceLanguages() {
    return new Promise((resolve) => {
        if (!window.speechSynthesis) {
            resolve(null)
        } else {
            const voices = window.speechSynthesis.getVoices();
            if (voices.length) {
                const langs = voices.map(v => v.lang)
                resolve(langs)
            } else {
                const voiceChanged = () => {
                    const voices = window.speechSynthesis.getVoices();
                    if (voices.length > 0) {
                        window.speechSynthesis.removeEventListener('voiceschanged', voiceChanged);
                        const langs = voices.map(v => v.lang)
                        resolve(langs);
                    }
                }
                window.speechSynthesis.addEventListener('voiceschanged', voiceChanged);
            }
        }
    })
}

export async function findVoiceLanguage(appLang) {
    if (!window.speechSynthesis) {
        return null
    }

    const voices = await getAvailableVoiceLanguages()

    if (voices.length) {
        if (appLang === 'en') {
            // preference is en-GB, but can also be en-US (different browsers might return en_GB, en_US, ...)
            const enGb = /^en.GB.*$/i
            const voiceLangEnGb = voices.find(v => v.match(enGb))
            if (voiceLangEnGb) {
                return voiceLangEnGb
            }

            const enUS = /^en.US.*$/i
            const voiceLangEnUS = voices.find(v => v.match(enUS))
            if (voiceLangEnUS) {
                return voiceLangEnUS
            }
        } else if (appLang === 'lt') {
            const ltLt = /^lt.LT.*$/i
            const voiceLangLt = voices.find(v => v.match(ltLt))
            if (voiceLangLt) {
                return voiceLangLt
            }
        } else if (appLang === 'no') {
            const nbNo = /^nb.NO.*$/i
            const voiceLangNo = voices.find(v => v.match(nbNo))
            if (voiceLangNo) {
                return voiceLangNo
            }
        }
    }

    return null
}