import React, { useEffect, useState } from 'react'
import { useLocation, Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser';

import RecipeSummaryView from '../components/RecipeSummaryView'
import {translatedTitle} from "../util/i18n";
import {createNewWeekMenu} from '../util/api'
import {saveWeekRecipes} from '../util/storage'

import './DaysListView.css'

const DayRecipeView = (recipe, index) => {
    const day = new Date()
    day.setDate(day.getDate() + index)
    const dateText = day.toISOString().split('T')[0]
    return (
        <div key={index + '-' + recipe.id} className='recipe-day-list-item'>
            <div className="day-number" id={dateText}>{dateText} &nbsp;</div>
            <RecipeSummaryView recipe={recipe} mixpanelEvent="WeekRecipes" />
        </div>
    )
}

export default function DaysListView() {
    const { state } = useLocation();
    const {t} = useTranslation();
    const [localRecipies, setLocalRecipies] = useState(state.recipies);

    const scrollToCurrentDate = () => {
        const day = new Date()
        const dateText = day.toISOString().split('T')[0]
        const element = document.getElementById(dateText);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleCreateNewWeekMenu = async () => {
        const newRecipes = await createNewWeekMenu(7)
        saveWeekRecipes(newRecipes)
        setLocalRecipies(newRecipes)
    }

    useEffect(() => {
        setTimeout(scrollToCurrentDate, 1000)
    }, [localRecipies]);

    return (
        <>
            <div className="recipe-create-new-list">
                <a target="_blank" onClick={() => handleCreateNewWeekMenu()}> {t('Update weekly menu')} </a>
            </div>
            <div className="recipe-day-list-view">
                {localRecipies?.map((recipe, index) => DayRecipeView(recipe, index)) }
            </div>
        </>
    )
}
