import { useEffect, useState } from 'react'
//import { findAllIngredients } from "../util/recipe"
import { useTranslation } from "react-i18next"
import Autosuggest from 'react-autosuggest'

import { Chips } from '@navikt/ds-react'

import { userLanguage } from '../util/usersettings'

import './autosuggest.css'
import './FoodFilter.css'

export default function FoodFilter({homeIngridients, onUpdateSelectedIngridients}) {
    const { t } = useTranslation()

    const [value, setValue] = useState('');
    const [ingredients, setIngredients] = useState([])
    const [suggestions, setSuggestions] = useState([])
    const [selectedIngredients, setSelectedIngredients] = useState([])

    useEffect(() => {
        setSelectedIngredients(homeIngridients)
    })

    // useEffect( () => {
    //     setIngredients(findAllIngredients().map(i => {
    //         return i
    //     }))
    // }, [])


    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase()
        const inputLength = inputValue.length

        const lang = userLanguage();
      
        const matchingIngredients = inputLength === 0 ? [] : ingredients.filter(ingredient => {
            let translatedName = t(`fooditem.${ingredient.id}`, { lng: lang })
            if (translatedName.startsWith('fooditem.')) {
                // there was no translation
                translatedName = ingredient.id
            }
            if (!translatedName) {
                return false
            }
            return translatedName.toLowerCase().slice(0, inputLength) === inputValue
        }).filter(ingredient => {
            // filter already selected ingredients
            return !selectedIngredients.includes(ingredient.id)
        })

        return matchingIngredients.map(i => i.id)
    }

    const getSuggestionValue = suggestion => {
        return suggestion
    }

    const onChange = (event, { newValue }) => setValue(newValue)

    const onSuggestionsFetchRequested = ({ value }) => setSuggestions(getSuggestions(value))

    const onSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
        event.preventDefault();
        selectedIngredients.push(suggestion)
        onUpdateSelectedIngridients(selectedIngredients)
        setSelectedIngredients(selectedIngredients)
        setValue('')
    }

    const onSuggestionsClearRequested = () => setSuggestions([])

    const renderSuggestion = suggestion => {
        return <div>{translatedFoodName(suggestion)}</div>
    }

    const onDeleteSelectedFood = (title) => {
        const selectionAfterDeletion = selectedIngredients.filter(i => i !== title)
        onUpdateSelectedIngridients(selectionAfterDeletion)
        setSelectedIngredients(selectionAfterDeletion)
    }

    const inputProps = {
        placeholder: t('ingredients you have at home'),
        value: value,
        onChange: onChange
    }

    const translatedFoodName = (name) => {
        const translatedName = t(`fooditem.${name}`)
        if (translatedName.startsWith('fooditem.')) {
            return name
        }
        return translatedName;
    }

    const displaySelectedIngridients = selectedIngredients.map(i => {
        return (
            <Chips.Removable key={i} onClick={() => onDeleteSelectedFood(i)}>{translatedFoodName(i)}</Chips.Removable>
        )
    })

    return (
        <div>
            <Autosuggest
                id="food"
                suggestions={suggestions}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                getSuggestionValue={getSuggestionValue}
                onSuggestionSelected={onSuggestionSelected}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
            />
            <div className='food-filter-selections'>
                <Chips>
                    {displaySelectedIngridients}
                </Chips>
            </div>
        </div>
    )

}