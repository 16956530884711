export default {
    translation: {
        'app.scree.recipe-alternatives': 'Alternatives',
        'app.screen.shopping-list': 'Shopping list',
        'app.screen.foot-at-home': 'Food at home',
        'app.screen.profile': 'Profile',
        'app.screen.profile.food-preferences': 'Food Preferences',
        'app.screen.profile.language': 'Language',
        'app.screen.profile.share-app': 'Share App',
        'app.screen.recipeview.share-recipe': 'Share recipe',
        'app.screen.recipeview.ingredients-into-shopping-list': 'Add into shopping list',
        'app.screen.recipeview.all-ingredients-in-shopping-list': 'All ingredients in shopping list',
        'app.screen.recipeview.missing-ingredients-in-home-list': "We couldn't find recipe that contains ingredients from your home list, but we suggest you to try this recipe",
        'app.screen.profile_suggested_recipes': 'Last suggested recipe',
        'app.screen.profile.give-us-feedback': 'Give us feedback',
        'app.screen.profile.share-your-recipe': 'Share your recipe',
        'app.screen.filter-food-selection': 'What would you like',

        'app.screen.filter-food-selection.ingredients-to-use': 'Ingredients you want to use',

        'app.screen.favoritelist.pageTitle': 'Your favorite recipes',
        'app.screen.favoritelist.empty-list': 'You don\'t have selected any favorite recipes yet',

        'recipe-tag.general': 'General',
        'recipe-tag.dietary': 'Dietary',
        'recipe-tag.occasion': 'Occasion',
        'recipe-tag.flavor': 'Flavor',

        'appmenu.home': 'Home',
        'appmenu.food-at-home': 'Food at home',
        'appmenu.shopping-list': 'Shopping list',
        'appmenu.profile': 'Profile',

        'Copy and share the link below:': 'Copy and share the link below:',
        'What are we eating today?': 'What are we eating today?',
        'Make menu for a week': 'Make menu for a week',
        'Recipes for inspiration': 'Recipes for inspiration',
        'Your favorite recipes': 'Your favorite recipes',
        'Have at home': 'Have at home',

        //irene
        'Enter an item': 'Enter an item',
        'items': 'items',
        'Check all': 'Check all',
        'Uncheck all': 'Uncheck all',
        'Delete checked': 'Delete checked',
        'empty shopping list': 'Your shopping list seems to be empty.',
        'Do not ask if home food is empty': 'Do not ask if home food is empty',
        'Select': 'Select',
        'ingredients you have at home': 'ingredients you have at home',
        'Edit': 'Edit',
        'Save Changes': 'Save Changes',
        'Cancel': 'Cancel',
        'Select Language': 'Select Language',

        'Back': 'Back',
        'breakfast': 'Breakfast',
        'brunch': 'Brunch',
        'lunch': 'Lunch',
        'dinner': 'Dinner',
        'serving quantity': 'Servings',
        'serving time': 'Serving time',
        'ingredients': 'Ingredients',
        'cooking steps': 'Cooking steps',
        'Alternatives': 'Alternatives',

        'main_course': 'Main course',
        'soup': 'Soup',
        'dessert': 'Dessert',

        'Close': 'Close',
        'all ingredients': 'All ingredients',

        'vegan': 'Vegan',
        'vegetarian': 'Vegetarian',
        'gluten-free': 'Gluten-free',
        'keto': 'Keto',
        'dairy-free': 'Dairy-free',
        'nut-free': 'Nut-free',

        'birthday': 'Birthday',
        'christmas': 'Christmas',
        'valentines-day': 'Valentines-day',

        'spicy': 'Spicy',
        'sweet': 'Sweet',
        'easter': 'Easter',

        'easy': 'Easy',
        'medium': 'Medium',
        'hard': 'Hard',

        'level': 'Level',

        'Update weekly menu': 'Update weekly menu',
        'add as favorite': 'add as favorite',
        'Remove favorite': 'remove favorite',

    }
}
