import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getShoppingList, saveShoppingList } from '../util/storage'

function AddShoppingListItem ({setFoodList}) {
  const [newItem, setNewItem] = useState('')
  const { t } = useTranslation()

  const handleAddItem = () => {
    if (newItem !== '') {
      const newFoodItem = {
        name: newItem,
      }

      const shoppingList = getShoppingList()
      shoppingList.push(newFoodItem)
      saveShoppingList(shoppingList)

      setFoodList(shoppingList)

      setNewItem('')
    }
  }
  const handleInputChange = (event) => {
    setNewItem(event.target.value)
  }
  //press the enter button to add an item
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddItem()
    }
  }
  return (
    <div className="add-shopping-list-item">
      <i className="bi bi-plus fs-2" onClick={handleAddItem}></i>

      <input type="text" placeholder={t("Enter an item")} value={newItem} onChange={handleInputChange} onKeyDown={handleKeyDown} />
    </div>
  )
}

export default AddShoppingListItem