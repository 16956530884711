import { useNavigate } from "react-router-dom"
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { loadALlFavoriteRecipes } from '../util/storage'
import RecipeSummaryView from '../components/RecipeSummaryView'

import BackButton from "../images/back.png";

import './FavoriteListView.css'

export default function FavoriteListView() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [localRecipies] = useState( loadALlFavoriteRecipes() );

    return (
        <div className="favorite-recipes-view">
            <div className="favorite-recipes-title" key="favorite-recipes-title">
                <div key="back-navigation" className="back-navigation" onClick={() => navigate(-1)}>
                    <img src={BackButton} style={{width: '20px'}}/>
                </div>
                <div key="recipe-title" className="recipe-title">{t('app.screen.favoritelist.pageTitle')}</div>
            </div>
            {(!localRecipies || !localRecipies.length) &&
                <div key="empty-list">{t("app.screen.favoritelist.empty-list")}</div>
            }
            {localRecipies?.map((recipe) =>
                <RecipeSummaryView recipe={recipe} mixpanelEvent="FavoriteRecipes" key={recipe.id}/>
            )}
        </div>
    )
}
